import { v4 as uuid } from 'uuid';
import { minnieMouse } from '../patients';
import { mickAbae } from '../providers';
import { editableEncounter } from './editableEncounter';

export const encounterIdA = uuid();

export const encounterA = {
  patient: minnieMouse,
  encounterId: encounterIdA,
  provider: mickAbae,
  assessments: [{ description: 'assessments description', code: 'assessments code' }],
  editableEncounter,
};
