import { Standard } from '@getvim/vim-connect';
import { getWritebackAssessmentPayload } from './writebackAssessment';

function createFieldTemplate(fieldName: string): string {
  return `$$$$$$$$ ${fieldName} $$$$$$$$`;
}

export function getWritebackAllEncounterFieldsPayload(): Standard.WriteBacks.UpdateEncounter {
  return {
    subjective: {
      generalNotes: createFieldTemplate('subjective FREE TEXT'),
      reviewOfSystemsNotes: createFieldTemplate('ROS FREE TEXT'),
      historyOfPresentIllnessNotes: createFieldTemplate('HPI FREE TEXT'),
      chiefComplaintNotes: createFieldTemplate('chiefComplaintNotes'),
    },
    objective: {
      generalNotes: createFieldTemplate('objective FREE TEXT'),
      physicalExamNotes: createFieldTemplate('PE FREE TEXT'),
    },
    assessments: {
      generalNotes: createFieldTemplate('assessments generalNotes'),
      diagnosisCodes: getWritebackAssessmentPayload('R14.0').assessments?.diagnosisCodes,
    },
    plans: {
      generalNotes: createFieldTemplate('plans'),
      procedureCodes: [{ code: '3210F' }],
    },
    patientInstructions: { generalNotes: createFieldTemplate('patientInstructions') },
  };
}
