import { UpdateEncounterType } from '../../../../types';
import { getWritebackProceduresPayload } from './writebackProcedures';
import { getWritebackAssessmentPayload } from './writebackAssessment';
import { getWritebackAllEncounterFieldsPayload } from './writebackAllEncounterFields';
import { getWritebackFreeTextNotesPayload } from './writebackFreeTextNotes';
export * from './writebackAssessment';
export * from './useCases';

const functions: any = {
  [UpdateEncounterType.WRITEBACK_ALL_ENCOUNTER_FIELDS]: getWritebackAllEncounterFieldsPayload,
  [UpdateEncounterType.WRITEBACK_FREE_TEXT_NOTES]: getWritebackFreeTextNotesPayload,
  [UpdateEncounterType.WRITEBACK_ASSESSMENT]: getWritebackAssessmentPayload,
  [UpdateEncounterType.WRITEBACK_PROCEDURES]: getWritebackProceduresPayload,
};

export function getUpdateEncounterPayload(selectType: string, key: string): string {
  return functions[selectType](key);
}
