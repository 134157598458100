import { AutomationOperationType } from '@getvim/vim-connect-app';

export enum UpdateEncounterType {
  WRITEBACK_ALL_ENCOUNTER_FIELDS = 'writeback all encounter fields',
  WRITEBACK_FREE_TEXT_NOTES = 'writeback free text notes',
  WRITEBACK_ASSESSMENT = 'writeback assessment',
  WRITEBACK_PROCEDURES = 'writeback procedures',
}

export enum UpdateReferralType {
  REFERRAL_ALL = 'referral all',
}

export enum ConfigType {
  REFERRAL_ALL_CONFIG = 'referral all config',
}

export enum UIInjectionType {
  DI_SELECT_PROVIDER = 'diSelectProvider',
  LABS_SELECT_PROVIDER = 'labsSelectProvider',
  REFERRAL_SELECT_PROVIDER = 'referralSelectProvider',
}

export const OPERATIONS_MAP = {
  updateEncounter: Object.values(UpdateEncounterType),
  updateReferral: Object.values(UpdateReferralType),
  diSelectProvider: [UIInjectionType.DI_SELECT_PROVIDER],
  labsSelectProvider: [UIInjectionType.LABS_SELECT_PROVIDER],
  referralSelectProvider: [UIInjectionType.REFERRAL_SELECT_PROVIDER],
};

export enum OperationSubTypeMap {
  updateEncounter = 'updateEncounter',
  updateReferral = 'updateReferral',
  diSelectProvider = 'diSelectProvider',
  labsSelectProvider = 'labsSelectProvider',
  referralSelectProvider = 'referralSelectProvider',
}

export enum OperationSubType {
  WRITEBACK_ALL_ENCOUNTER_FIELDS = UpdateEncounterType.WRITEBACK_ALL_ENCOUNTER_FIELDS,
  WRITEBACK_FREE_TEXT_NOTES = UpdateEncounterType.WRITEBACK_FREE_TEXT_NOTES,
  WRITEBACK_ASSESSMENT = UpdateEncounterType.WRITEBACK_ASSESSMENT,
  WRITEBACK_PROCEDURES = UpdateEncounterType.WRITEBACK_PROCEDURES,
  REFERRAL_ALL = UpdateReferralType.REFERRAL_ALL,
  DI_SELECT_PROVIDER = UIInjectionType.DI_SELECT_PROVIDER,
  LABS_SELECT_PROVIDER = UIInjectionType.LABS_SELECT_PROVIDER,
  REFERRAL_SELECT_PROVIDER = UIInjectionType.REFERRAL_SELECT_PROVIDER,
}

export const LOG_TO_SHORT = {
  [OperationSubType.WRITEBACK_ALL_ENCOUNTER_FIELDS]: 'wbaef',
  [OperationSubType.WRITEBACK_FREE_TEXT_NOTES]: 'wbftn',
  [OperationSubType.WRITEBACK_ASSESSMENT]: 'wba',
  [OperationSubType.WRITEBACK_PROCEDURES]: 'wbp',
  [OperationSubType.REFERRAL_ALL]: 'ra',
  [UIInjectionType.DI_SELECT_PROVIDER]: 'dsp',
  [UIInjectionType.LABS_SELECT_PROVIDER]: 'lsp',
  [UIInjectionType.REFERRAL_SELECT_PROVIDER]: 'rsp',
};

export const SHORT_TO_LOG: {
  [k: string]: string;
} = Object.fromEntries(Object.entries(LOG_TO_SHORT).map(([key, value]) => [value, key]));

export const SHORT_TO_OPERATION_TYPE: {
  a: string;
  w: string;
  u: string;
} = {
  a: AutomationOperationType.ACTION,
  w: AutomationOperationType.WRITEBACK,
  u: AutomationOperationType.UI_INJECTION,
};

export const OPERATION_TYPE_TO_SHORT: {
  [k: string]: string;
} = Object.fromEntries(Object.entries(SHORT_TO_OPERATION_TYPE).map(([key, value]) => [value, key]));

export enum HiddenJsonType {
  EVENT = 'event',
  STATE = 'state',
  REPORT = 'report',
  CAPABILITIES = 'capabilities',
  WRITEBACK = 'writeback',
}

export const OPERATION_TYPE_LIST = ['ALL', ...Object.keys(AutomationOperationType)];
