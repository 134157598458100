import { EditableEncounter } from '@getvim-adapter/api';
import { encounterD } from './encounterD';

const { patient, editableEncounter, ...encounter } = encounterD;

export const nonEditableAssessment = {
  ...encounter,
  editableEncounter: {
    ...editableEncounter,
    assessment: undefined,
  } satisfies EditableEncounter,
};
