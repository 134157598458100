import { Standard } from '@getvim/vim-connect';
import {
  encounterA,
  encounterB,
  encounterC,
  encounterD,
  nonEditableAssessment,
} from '../encounters';

export const predefinedPayloads: Record<string, Standard.Events.EncounterStartPayload> = {
  'Minnie Mouse - Encounter A': encounterA,
  'Steven Vazquez - Encounter B': encounterB,
  'Natasha Davies - Encounter C': encounterC,
  'Patricia Bell - Encounter d': encounterD,
  'Assessment not editable': nonEditableAssessment,
};
