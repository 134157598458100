import { v4 as uuid } from 'uuid';
import { stevenVazquez } from '../patients';
import { mickAbae } from '../providers';
import { editableEncounter } from './editableEncounter';

export const encounterIdB = uuid();

export const encounterB = {
  patient: stevenVazquez,
  encounterId: encounterIdB,
  provider: mickAbae,
  assessments: [{ description: 'assessments description', code: 'assessments code' }],
  editableEncounter,
};
