import React from 'react';
import {
  AccountTree,
  Apps,
  AutoStories,
  BackupTable,
  BarChart,
  Bloodtype,
  Cable,
  CancelPresentation,
  Cancel,
  CellTower,
  CheckCircle,
  Clear,
  ContentPasteSearch,
  CreateNewFolder,
  Dangerous,
  Delete,
  DeleteForever,
  DirectionsRun,
  Download,
  DynamicFeed,
  ExitToApp,
  ExpandLess,
  ExpandMore,
  Feed,
  FileOpen,
  Folder,
  HealthAndSafety,
  Healing,
  LibraryBooks,
  LocalHospital,
  LocalPharmacy,
  Login,
  Logout,
  MedicalInformation,
  Medication,
  MonitorHeart,
  OpenWith,
  Settings,
  SettingsRemote,
  Sync,
  Update,
  Vaccines,
  VolunteerActivism,
  VpnKey,
  Warning,
  PrecisionManufacturing,
  SwitchAccount,
  PermContactCalendar,
  Description,
  Publish,
  FolderShared,
  PersonSearch,
  CalendarMonth,
  Summarize,
  MoveDown,
  LocalPrintshop,
  ScreenSearchDesktop,
  KeyboardDoubleArrowUp,
  KeyboardDoubleArrowDown,
} from '@mui/icons-material';
import { Standard } from '@getvim/vim-connect';
import { OperationSubType } from './operations';
import { TabType } from './tabs';

const { ActionNames } = Standard.Actions;

export const ICONS_MAP = {
  onLoggedIn: <Login />,
  logout: <Logout />,
  patientInContext: <LocalHospital />,
  patientOutOfContext: <DirectionsRun />,
  patientChartOpened: <FileOpen />,
  patientChartClosed: <ExitToApp />,
  encounterStart: <MedicalInformation />,
  encounterUpdated: <Update />,
  encounterExit: <VolunteerActivism />,
  referralViewed: <LocalPharmacy />,
  referralClosed: <CreateNewFolder />,
  referralSaved: <HealthAndSafety />,
  referralDeleted: <DeleteForever />,
  orderCreated: <MonitorHeart />,
  orderViewed: <Medication />,
  orderClosed: <Healing />,
  ordersViewed: <DynamicFeed />,
  ordersClosed: <CancelPresentation />,
  sendAnalytics: <BarChart />,
  corruptedEhrState: <Dangerous />,
  sendEhrData: <Folder />,
  [OperationSubType.WRITEBACK_ALL_ENCOUNTER_FIELDS]: <BackupTable />,
  [OperationSubType.WRITEBACK_FREE_TEXT_NOTES]: <BackupTable />,
  [OperationSubType.WRITEBACK_ASSESSMENT]: <BackupTable />,
  [OperationSubType.WRITEBACK_PROCEDURES]: <AccountTree />,
  [OperationSubType.REFERRAL_ALL]: <Apps />,
  [OperationSubType.DI_SELECT_PROVIDER]: <Vaccines />,
  [OperationSubType.LABS_SELECT_PROVIDER]: <Bloodtype />,
  [OperationSubType.REFERRAL_SELECT_PROVIDER]: <AutoStories />,
  [TabType.EVENTS]: <CellTower />,
  [TabType.STATE]: <AccountTree />,
  [TabType.ACTIONS]: <SettingsRemote />,
  [TabType.CONNECTORS]: <Cable />,
  [TabType.LOGS]: <Feed />,
  [TabType.REPORTS]: <ContentPasteSearch />,
  [TabType.AUTOMATIONS]: <PrecisionManufacturing />,
  [TabType.SETTINGS]: <Settings />,
  ['delete']: <Delete />,
  ['expand-more']: <ExpandMore />,
  ['expand-less']: <ExpandLess />,
  ['open-with']: <OpenWith />,
  ['clear']: <Clear />,
  ['sync']: <Sync />,
  ['download']: <Download />,
  ['password']: <VpnKey />,
  ['warning']: <Warning />,
  ['success']: <CheckCircle />,
  ['log-success']: <CheckCircle />,
  ['log-fail']: <Cancel />,
  ['sort-up']: <KeyboardDoubleArrowUp />,
  ['sort-down']: <KeyboardDoubleArrowDown />,
  ['scroll-top']: <Publish />,
  [ActionNames.FIND_PATIENT]: <SwitchAccount />,
  [ActionNames.GET_CURRENT_EHR_USER_INFORMATION]: <PermContactCalendar />,
  [ActionNames.GET_ENCOUNTER_DATA]: <Description />,
  [ActionNames.GET_GAPS_FROM_SHARED_REQUEST]: <FolderShared />,
  [ActionNames.GET_PATIENT]: <PersonSearch />,
  [ActionNames.GET_PATIENT_ENCOUNTERS]: <LibraryBooks />,
  [ActionNames.GET_PATIENT_SCHEDULED_APPOINTMENTS]: <CalendarMonth />,
  [ActionNames.GET_PROVIDER_DAILY_SUMMARY]: <Summarize />,
  [ActionNames.NAVIGATE_TO_ENCOUNTER]: <MoveDown />,
  [ActionNames.PRINT_ENCOUNTER]: <LocalPrintshop />,
  [ActionNames.PRINT_ENCOUNTERS]: <LibraryBooks />,
  [ActionNames.SEARCH_PROVIDERS]: <ScreenSearchDesktop />,
};
